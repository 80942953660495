import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import Header from "../../component/Header";
import IconArrowLeft from "../../component/SvgIcons/IconArrowLeft";
import IconDownload from "../../component/SvgIcons/IconDownload";
import IconSearch from "../../component/SvgIcons/IconSearch";
import api from "../utils/axios";
import "./style.css";
import IconArrowRight from "../../component/SvgIcons/IconArrowRight";
import LoadingData from "../../component/Loading";
import { useToast } from "../utils/ToastProvider";

export default function TotalStockQuantity() {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedGodown, setSelectedGodown] = useState("");
  const [data, setData] = useState([]);
  const [godowns, setGodowns] = useState([]);
  const [filteredData, setFilteredData] = useState([]);  
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedImino, setSelectedImino ] = useState("");
  const [loading, setLoading] = useState(false);
  const [isSelectDisabled, setIsSelectDisabled] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [itemsPerPage] = useState(20);
  const CompanyId = localStorage.getItem("CmpGUID");
  const notify = useToast();

  useEffect(() => {
    const fetchGodowns = async () => {
      try {
        const response = await api.post('/Godownnamelist', {
          CmpGUID: CompanyId 
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        });          
             
        if (response.data && response.data.ShopDetail) {          
          const newCompanyData = response.data.ShopDetail.map((element) => ({
            id: element["imeiNumber"],
            value: element["GoDownName"],
            label: element["GoDownName"],            
          }));  
          setGodowns(newCompanyData);           
        } else {
          setGodowns([]);
          notify("No shop details found.", "error");
        }
      } catch (error) {
        console.error("Error fetching Godowns:", error.response ? error.response.data : error.message);
        setGodowns([]);
        notify("Error fetching godowns.", "error");
      }
    };
    fetchGodowns();
  }, [CompanyId, notify]);

  useEffect(() => {
    setTotalPages(Math.ceil(filteredData.length / itemsPerPage));
  }, [filteredData, itemsPerPage]);

  const handleSearch = async () => {
  setLoading(true);
  setIsSelectDisabled(true);
  setIsButtonDisabled(true);
  
  
  if (selectedImino !== '') {     
    try {
      const response = await api.post('/GarsItemDetails', {
        CmpGUID: CompanyId,
        IMEINumber: selectedImino,
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      
      if (response.data && response.data.ItemDetails) {
        setData(response.data.ItemDetails);
        filterData(response.data.ItemDetails);
        notify("Data fetching success", "success");
      } else {
        setData([]);
        setFilteredData([]);        
        notify("No item details found.", "error");
      }
    } catch (error) {
      //console.error("Error fetching data:", error.response ? error.response.data : error.message);
      //setError("Error fetching data. Please try again later.");
      notify("Error fetching data. Please try again later.", "error");
      setData([]);
      setFilteredData([]);
    } finally {
      setLoading(false);
      setIsSelectDisabled(false);
      setIsButtonDisabled(false);
    }
  }else{
    setLoading(false);
    setIsSelectDisabled(false);
    setIsButtonDisabled(false);
  }
  
  };

  const filterData = (data) => {
    const lowercasedQuery = searchQuery.toLowerCase();
    const filtered = data.filter(item => 
      Object.values(item).some(value => 
        value && value.toString().toLowerCase().includes(lowercasedQuery)
      )
    );
    setFilteredData(filtered);
  };

  const handleGodownChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedGodown(selectedValue);
    const selectedGodownData = godowns.find(godown => godown.value === selectedValue);
    setSelectedImino(selectedGodownData.id);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    filterData(data);
  };
  const handleDownload = () => {
    if (filteredData.length > 0) {      
      const dataWithSerialNumber = filteredData.map((item, index) => ({
        No: startIndex + index + 1,
        ...item,
      }));        
      const ws = XLSX.utils.json_to_sheet(dataWithSerialNumber);
      const wb = XLSX.utils.book_new();        
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");        
      XLSX.writeFile(wb, "godownDetails.xlsx");
    } else {
      alert("No data to download.");
    }
  };
  

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, filteredData.length);
  const currentData = filteredData.slice(startIndex, endIndex);

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const getPagination = () => {
    const pages = [];
    const maxPagesToShow = 5;
    const startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
    const adjustedStartPage = Math.max(1, endPage - maxPagesToShow + 1);

    if (currentPage > 1) {
      pages.push(
        <li key="prev">
          <Link 
            to="#" 
            onClick={() => handlePageChange(currentPage - 1)}
            className="pagination-link"
          >
            <IconArrowLeft />
          </Link>
        </li>
      );
    }

    if (adjustedStartPage > 1) {
      pages.push(
        <li key="first">
          <Link 
            to="#" 
            onClick={() => handlePageChange(1)}
            className="pagination-link"
          >
            1
          </Link>
        </li>
      );

      if (adjustedStartPage > 2) {
        pages.push(
          <li key="ellipsis-start">
            <span className="pagination-link">...</span>
          </li>
        );
      }
    }

    for (let page = adjustedStartPage; page <= endPage; page++) {
      pages.push(
        <li key={page}>
          <Link 
            to="#" 
            onClick={() => handlePageChange(page)}
            className={`pagination-link ${currentPage === page ? 'active' : ''}`}
          >
            {page}
          </Link>
        </li>
      );
    }

    if (endPage < totalPages - 1) {
      pages.push(
        <li key="ellipsis-end">
          <span className="pagination-link">...</span>
        </li>
      );
    }
    if (endPage < totalPages) {
      pages.push(
        <li key="last">
          <Link 
            to="#" 
            onClick={() => handlePageChange(totalPages)}
            className="pagination-link"
          >
            {totalPages}
          </Link>
        </li>
      );
    }
    if (currentPage < totalPages) {
      pages.push(
        <li key="next">
          <Link 
            to="#" 
            onClick={() => handlePageChange(currentPage + 1)}
            className="pagination-link"
          >
            <IconArrowRight />
          </Link>
        </li>
      );
    }

    return pages;
  };

  return (
    <section className="StockQuantity">
      <Header pageTitle="Godown - wise Item Count and Stock Quantities" />
      {/* Total Item & Stock Quantity - Godown wise Items & Stock Quantity */}
      <div className="pageLeftspace">
        <div className="tableheader d-flex justify-content-between align-items-center">
          <div className="col-6 flex-row">
            <Link to="/dashboard" className="btn btn-danger addbutton">
              <IconArrowLeft /> Back
            </Link>
            <button className="btn btn-purpal addbutton" onClick={handleDownload}>
              <IconDownload /> Download To Excel
            </button>
          </div>

          <div className="rightside search-bar">
            <div className="selectoption">
              <select className="form-control" value={selectedGodown} onChange={handleGodownChange} disabled={isSelectDisabled}>
                <option value="">Select Godown</option>
                  {godowns.length > 0 ? (
                    godowns.map((godown, index) => (
                      <option key={index} value={godown.value}>
                        {godown.label}
                      </option>
                    ))
                  ) : (
                    <option disabled>wait data fetch now</option>
                  )}
              </select>              
              <span>
                <IconArrowLeft />
              </span>
            </div>
            <button className="btn btn-purpal" onClick={handleSearch} disabled={isButtonDisabled}>
              {/* <span>
                <IconSearch />
              </span> */}
             Go
            </button>
            <div className="search-status">
              <input
                type="text"
                value={searchQuery}
                onChange={handleSearchChange}
                className="form-control"
                placeholder="Search..."
              />
              <span>
                <IconSearch />
              </span>
            </div>
          </div>
        </div>

          <div className="row">
            <div className="col-12 mb-4">
            {loading ? (
              <LoadingData/>
            ):(
              <div className="detailstable">                
                {filteredData.length > 0 ? (
                  <div className="tablewithpaginamtion">
                    <table className="table">
                      <thead>
                        <tr>
                          <th width="20">No.</th>
                          <th>Item Name</th>
                          {/* <th>Item Master ID</th> */}
                          <th>Alias</th>
                          <th>Group</th>
                          <th>Category</th>
                          <th>Unit</th>
                          <th>Batch Wise</th>
                          <th>Tax Per</th>
                          <th>Company Name</th>
                          <th>Quantity</th>
                          <th>Batch Name</th>
                          <th>Rate</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentData.map((item, index) => (
                          <tr key={index}>
                            <td className="action">{startIndex + index + 1}</td>
                            <td>{item.ItemName}</td>
                            {/* <td>{item.ItemMasterID}</td> */}
                            <td>{item.ItemAlias}</td>
                            <td>{item.ItemGroup}</td>
                            <td>{item.ItemCategory}</td>
                            <td>{item.ItemUnit}</td>
                            <td>{item.ItemBatchWise}</td>
                            <td>{item.TaxPer}</td>
                            <td>{item.CompanyName}</td>
                            <td>{item.ItemQty}</td>
                            <td>{item.BatchName}</td>
                            <td>{item.ItemRate}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <ul className="pagination d-flex align-items-center justify-content-center">
                      {getPagination()}
                    </ul>
                  </div>
                ) : (
                  <div className="text-center">Please select godown</div>
                )}                
              </div>
               )}
            </div>
          </div>
      </div>
    </section>
  );
}
