import React from "react";


export default function TransferReceiveDetails({activeItem}) {
  if (!activeItem) {
    return <div>No details available</div>;
  }  
  return (
    <div className="detailstable transfaldetailstable">
      <h5>Receive Details</h5>
      <table className="table">     
        <tbody>
            <tr>
            <th>Receive No	</th> <td>{activeItem.ReceiveNo}</td>
            </tr>
            <tr>
                <th>Receive Date</th>
              <td>{activeItem.ReceiveDate}</td>
              </tr>
              <tr>
                <th>Receive Time</th>
              <td>{activeItem.ReceiveTime}</td>
              </tr>
              <tr>
              <th>Receive To</th>
              <td>{activeItem.RecieveTo}</td>
              </tr>             
              <tr>
                <th>Sender Vch Number</th>
              <td>{activeItem.SenderVchNumber}</td>
              </tr>
              <tr>
               <th>Receive Type</th>
              <td>{activeItem.ReceiveType}</td>              
              </tr>
              <tr>
              <th>IMEI Number</th>
              <td>{activeItem.IMEINumber}</td>   
              </tr> 
              {activeItem.Narrations && (
            <tr>
              <th>Narrations</th>
              <td>{activeItem.Narrations}</td>
            </tr>
          )}  
                         
              <tr>
              <th>Received By Tally</th>
              <td>YES</td>              
            </tr>          
        </tbody>
      </table>
    </div>
  );
}
