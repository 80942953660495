import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import IconArrowLeft from '../SvgIcons/IconArrowLeft';
import IconArrowRight from '../SvgIcons/IconArrowRight';

export default function TransferInventoryReceiveDetails({ salesInventoryDetails }) {  
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const inventoryData = Array.isArray(salesInventoryDetails) ? salesInventoryDetails : [];
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = inventoryData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(inventoryData.length / itemsPerPage);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div className='detailstable Inventorydata'>
      <h5>Receive Inventory Details</h5>
      {currentItems.length > 0 ? (
        <table className="table">
          <thead>
            <tr>
              <th width="30" className='text-center'>No.</th>
              <th>Name</th>
              <th>Group</th>               
              <th width="30">Quantity</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item, index) => (
              <tr key={item.id || `${item.ItemName}-${index}`}> 
                <td className='text-center'>{indexOfFirstItem + index + 1}</td>
                <td>{item.ItemName}</td>
                <td>{item.ItemCategory || 'N/A'}</td> 
                <td className='action'>{item.ItemQty}</td> 
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No inventory details available.</p>
      )}

      {/* Show pagination only if there are more than 20 items */}
      {inventoryData.length > itemsPerPage && (
        <ul className='pagination d-flex align-items-center justify-content-end'>
          <li>
            <Link 
              to="#" 
              onClick={() => handlePageChange(currentPage - 1)}
              className={`pagination-link ${currentPage === 1 ? 'disabled' : ''}`}
            >
              <IconArrowLeft/>
            </Link>
          </li>
          {Array.from({ length: totalPages }, (_, index) => (
            <li key={index + 1}>
              <Link 
                to="#" 
                onClick={() => handlePageChange(index + 1)}
                className={`pagination-link ${currentPage === index + 1 ? 'active' : ''}`}
              >
                {index + 1}
              </Link>
            </li>
          ))}
          <li>
            <Link 
              to="#" 
              onClick={() => handlePageChange(currentPage + 1)}
              className={`pagination-link ${currentPage === totalPages ? 'disabled' : ''}`}
            >
              <IconArrowRight/>
            </Link>
          </li>
        </ul>
      )}
    </div>    
  );
}
