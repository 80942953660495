import React from "react";

export default function IconSearch() {
  return (
    <svg
      clipRule="evenodd"
      fillRule="evenodd"
      height="25"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      viewBox="0 0 24 24"
      width="25"
      xmlns="http://www.w3.org/2000/svg"
      id="fi_5636698"
    >
      <g id="Icon">
        <g>
          <path d="m10 3.5c-3.587 0-6.5 2.913-6.5 6.5s2.913 6.5 6.5 6.5 6.5-2.913 6.5-6.5-2.913-6.5-6.5-6.5zm0 1c3.036 0 5.5 2.464 5.5 5.5s-2.464 5.5-5.5 5.5-5.5-2.464-5.5-5.5 2.464-5.5 5.5-5.5z"></path>
          <path d="m20.354 19.646-5.759-5.758c-.195-.195-.512-.195-.707 0s-.195.512 0 .707l5.758 5.759c.196.195.512.195.708 0 .195-.196.195-.512 0-.708z"></path>
        </g>
      </g>
    </svg>
  );
}
