import React from "react";

export default function IconTranfare() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"      
      id="fi_3029336"
    >
      <g
        id="Ecommerce-transfer_money-buy-smartphone-hands"
        data-name="Ecommerce-transfer money-buy-smartphone-hands"
      >
        <path d="M56.293,14.293l1.414,1.414,4-4a1,1,0,0,0,0-1.414l-4-4L56.293,7.707,58.586,10H47v2H58.586Z"></path>
        <path d="M50.293,23.707l1.414-1.414L49.414,20H61V18H49.414l2.293-2.293-1.414-1.414-4,4a1,1,0,0,0,0,1.414Z"></path>
        <path d="M60.83,31.17a3.979,3.979,0,0,0-5.41-.21A3.966,3.966,0,0,0,52,29a3.56,3.56,0,0,0-1.31.23,3.839,3.839,0,0,0-1.26.74A4,4,0,0,0,46,28a3.953,3.953,0,0,0-2,.55V12a4,4,0,0,0-4-4,3.953,3.953,0,0,0-2,.55V5a3.009,3.009,0,0,0-3-3H11A3.009,3.009,0,0,0,8,5V25H7a5,5,0,0,0-5,5v4.09L4,55.05V61a1,1,0,0,0,1,1H27a1,1,0,0,0,1-1V55.32L31.72,50h2.15L35,53.17V61a1,1,0,0,0,1,1H56a1,1,0,0,0,1-1V53.17l4.59-12.86A7,7,0,0,0,62,37.96V34A4,4,0,0,0,60.83,31.17ZM26,60H6V56H26Zm.48-6H5.91L4,34V30a3.009,3.009,0,0,1,3-3h7a3,3,0,1,1,0,6H9a1,1,0,0,0-1,1V47a3.009,3.009,0,0,0,3,3H29.28ZM11,48a1,1,0,0,1-1-1V44H28V42H10V35h4a5,5,0,0,0,0-10H10V5a1,1,0,0,1,1-1H35a1,1,0,0,1,1,1V25.56A3.927,3.927,0,0,0,33.99,25,4,4,0,0,0,30,28.99v8.97a7.1,7.1,0,0,0,.41,2.36L33.15,48ZM40,60H37V54h3Zm5,0H42V54h3Zm5,0H47V54h3Zm5,0H52V54h3Zm5-22.04a5.073,5.073,0,0,1-.29,1.68L55.3,52H36.7L32.29,39.65A5.176,5.176,0,0,1,32,37.96V28.99A2,2,0,0,1,36,29v8h2V12a2.015,2.015,0,0,1,2-2,2.006,2.006,0,0,1,2,2V37h2V32a2.015,2.015,0,0,1,2-2,2.006,2.006,0,0,1,2,2v5h2V33a1.983,1.983,0,0,1,.59-1.41,1.8,1.8,0,0,1,.77-.48A1.868,1.868,0,0,1,52,31a2.006,2.006,0,0,1,2,2v5h2V34a2.006,2.006,0,0,1,2-2,2.015,2.015,0,0,1,2,2Z"></path>
        <path d="M23,6A10,10,0,1,0,33,16,10.016,10.016,0,0,0,23,6Zm0,18a8,8,0,1,1,8-8A8.011,8.011,0,0,1,23,24Z"></path>
        <path d="M26,18a3.01,3.01,0,0,1-2,2.82V22H22V20.82A3.01,3.01,0,0,1,20,18h2a1,1,0,1,0,1-1,3,3,0,0,1-1-5.82V10h2v1.18A3.01,3.01,0,0,1,26,14H24a1,1,0,1,0-1,1A3.009,3.009,0,0,1,26,18Z"></path>
      </g>
    </svg>
  );
}
