import React from 'react'

export default function LoadingData() {
  return (
    <div className="detailstable text-center">
    <div className="spinner-border" role="status">
      {/* <span className="sr-only">Loading...</span> */}
    </div>
  </div>
  )
}
