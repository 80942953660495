import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../component/Header";
import IconArrowRight from "../../component/SvgIcons/IconArrowRight";
import IconRecive from "../../component/SvgIcons/IconRecive";
import IconStock from "../../component/SvgIcons/IconStock";
import IconTotalInvoice from "../../component/SvgIcons/IconTotalInvoice";
import IconTranfare from "../../component/SvgIcons/IconTranfare";
import "./style.css";
import api from "../utils/axios";

export default function Dashboard() {
  const [stockdata, setStockdata] = useState('');
  const [invoicedata, setInvoicedata] = useState('');
  const [transferdata, setTransferdata] = useState('');
  const [receivedata, setReceivedata] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchTotalCountData = async () => {
      const userID = localStorage.getItem("UserId");
      const cmpGUID = localStorage.getItem("CmpGUID");
      try {
        const response = await api.post('/TotalCountdata', {
          UserID: userID,
          CmpGUID: cmpGUID,
        }, {
          headers: {
            'Content-Type': 'application/json',
          }
        });
        if (response.data) {
          setStockdata(response.data.ItemCount[0]["Count(*)"] || 0);
          setInvoicedata(response.data.InvoiceCount[0]["Count(*)"] || 0);
          setTransferdata(response.data.StoclTransferCount[0]["Count(*)"] || 0);
          setReceivedata(response.data.StockReceiveCount[0]["Count(*)"] || 0);          
          setError(null);
        } else {
          setError("Failed to fetch data.");
        }
      } catch (error) {
        console.error("Error fetching data:", error.response ? error.response.data : error.message);
        setError("Error fetching data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
    fetchTotalCountData();
  }, []);
  return (
    <section className="dashboardpage">
      <Header pageTitle="Dashboard" />
      <div className="pageLeftspace">
        <div className="container">
          {/* <div className="col-lg-12 text-center mb-5 titlebox">
          <h3>Count Of All Entries Till Now</h3>
          </div> */}
          <div className="row">
            <div className="col-lg-3 col-6">
              <div className="small-box bg-info">
                <div className="inner">
                <h3>
                {loading ? <p>Loading...</p> : error ? <p>{error}</p> : invoicedata}
              </h3>
                  <p>Total Invoice</p>
                </div>
                <div className="icon">
                  <IconTotalInvoice/>
                </div>
                <Link to="/total-invoice" className="small-box-footer">
                  More info <IconArrowRight/>
                </Link>
              </div>
            </div>

            <div className="col-lg-3 col-6">
              <div className="small-box bg-success">
                <div className="inner">
                  <h3>
                  {loading ? <p>Loading...</p> : error ? <p>{error}</p> : transferdata}                 
                  </h3>
                  <p>Total Transfer</p>
                </div>
                <div className="icon">
                  <IconTranfare/>
                </div>
                <Link to="/total-transfer" className="small-box-footer">
                  More info <IconArrowRight/>
                </Link>
              </div>
            </div>

            <div className="col-lg-3 col-6">
              <div className="small-box bg-warning">
                <div className="inner">
                  <h3>
                  {loading ? <p>Loading...</p> : error ? <p>{error}</p> : receivedata}      
                  </h3>
                  <p>Total Receive</p>
                </div>
                <div className="icon">                  
                  <IconRecive/>
                </div>
                <Link to="/total-received" className="small-box-footer">
                  More info <IconArrowRight/>
                </Link>
              </div>
            </div>

            <div className="col-lg-3 col-6">
              <div className="small-box bg-danger">
                <div className="inner">
                  <h3> {loading ? <p>Loading...</p> : error ? <p>{error}</p> : stockdata}</h3>
                  <p>Total Item & Stock Quantity</p>
                </div>
                <div className="icon">
                 <IconStock/>
                </div>
                <Link to="/total-stock-quantity" className="small-box-footer">
                  More info <IconArrowRight/>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
