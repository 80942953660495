import React, { createContext, useContext, useCallback } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Create context
const ToastContext = createContext();

// Create a provider component
export const ToastProvider = ({ children }) => {
  // Function to show a toast message
  const showToast = useCallback((message, type = 'default') => {
    switch (type) {
      case 'success':
        toast.success(message);
        break;
      case 'error':
        toast.error(message);
        break;
      case 'info':
        toast.info(message);
        break;
      case 'warn':
        toast.warn(message);
        break;
      default:
        toast(message);
        break;
    }
  }, []);

  return (
    <ToastContext.Provider value={showToast}>
      {children}
      <ToastContainer/>
    </ToastContext.Provider>
  );
};
export const useToast = () => {
  return useContext(ToastContext);
};
