import React from "react";


export default function TransferDetailsTotal({activeItem}) {
  if (!activeItem) {
    return <div>No details available</div>;
  }  
  return (
    <div className="detailstable transfaldetailstable">
      <h5>Transfer Details</h5>
      <table className="table">     
        <tbody>
            <tr>
            <th>Transfer No	</th> <td>{activeItem.TransferNo}</td>
            </tr>
            <tr>
                <th>Transfer Date</th>
              <td>{activeItem.TransferDate}</td>
              </tr>
              <tr>
                <th>Transfer Time</th>
              <td>{activeItem.TransferTime}</td>
              </tr>
              <tr>
              <th>Transfer From	</th>
              <td>{activeItem.TransferFrom}</td>
              </tr>
              <tr>
                <th>Transfer To</th>
              <td>{activeItem.TransferTo}</td>
              </tr>
              <tr>
                <th>Transfer IMEI Number To	</th>
              <td>{activeItem.TransferIMEINumberTo}</td>
              </tr>
              <tr>
               <th>Transfer Type</th>
              <td>{activeItem.TransferType}</td>              
              </tr>
              <tr>
              <th>Total Amount</th>
              <td>{activeItem.TotalAmount}</td>   
              </tr> 
              {activeItem && activeItem.Narrations && (
                <tr>
                  <th>Narration</th>
                  <td>{activeItem.Narrations}</td>
                </tr>
              )}
              <tr>
              <th>IMEI Number</th>
              <td>{activeItem.IMEINumber}</td>  
              </tr>            
              <tr>
              <th>Received By Tally</th>
              <td>{activeItem.SendFlag}</td>              
            </tr>          
        </tbody>
      </table>      
    </div>
  );
}
