import React from "react";

export default function IconDownload() {
  return (
    <svg
      height="20"
      viewBox="0 0 64 64"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
      id="fi_3185874"
    >
      <g id="Layer_41" data-name="Layer 41">
        <path d="m57.47 38.6a2 2 0 0 0 -2 2v6.83a6.07 6.07 0 0 1 -6.07 6.07h-34.8a6.07 6.07 0 0 1 -6.07-6.07v-6.83a2 2 0 1 0 -4 0v6.83a10.08 10.08 0 0 0 10.07 10.07h34.8a10.08 10.08 0 0 0 10.07-10.07v-6.83a2 2 0 0 0 -2-2z"></path>
        <path d="m30.58 44.44a2 2 0 0 0 2.84 0l11.38-11.38a2 2 0 0 0 -2.8-2.83l-8 8v-29.73a2 2 0 0 0 -4 0v29.7l-8-8a2 2 0 0 0 -2.83 2.83z"></path>
      </g>
    </svg>
  );
}
