import React from "react";

export default function IconArrowRight() {
  return (
    <svg
      id="fi_5436598"
      enableBackground="new 0 0 64 64"
      height="18"
      viewBox="0 0 64 64"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="m42.7 29.6c-5.5-5.5-11-11-16.5-16.5-.8-.8-1.6-1.6-2.4-2.4-1.8-1.8-4.7 1-2.8 2.8 5.5 5.5 11 11 16.5 16.5.3.3.6.6.9.9-5 5-10 10-15 15-.8.8-1.5 1.5-2.3 2.3-1.8 1.8 1 4.7 2.8 2.8l16.4-16.4 2.3-2.3c.9-.6.9-1.9.1-2.7z"></path>
    </svg>
  );
}
