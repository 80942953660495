import React from "react";

export default function IconRecive() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"      
      id="fi_6384822"
    >
      <g id="Layer_7" data-name="Layer 7">
        <path d="M12.6,31.751,1.385,27.078l-.77,1.845L8,32v9a1,1,0,0,0,1,1h5.055a13.279,13.279,0,0,0,1.823,2.293,2.408,2.408,0,0,0,3.742-.418l.193.243a2.342,2.342,0,0,0,4.082-.811,2.4,2.4,0,0,0,3.772-.518A2.346,2.346,0,0,0,30.561,42H45a1,1,0,0,0,1-1V9a1,1,0,0,0-1-1H9A1,1,0,0,0,8,9v8.339L1.543,13.161.457,14.84,16.63,25.305ZM10,40V32.834l2,.833v1.262A13.224,13.224,0,0,0,13,40Zm18.36.87-4.591-5.51-1.538,1.281,2.487,2.983,1.131,1.508a.764.764,0,0,1,.151.454.43.43,0,0,1-.707.293l-4.586-4.586-1.414,1.415,1.885,1.885.637,1.273a1.75,1.75,0,0,1,.185.783.365.365,0,0,1-.624.22l-3.6-4.493-1.562,1.249,1.678,2.1a3.822,3.822,0,0,1,.1.864.43.43,0,0,1-.707.293A11.173,11.173,0,0,1,14,34.929V33.286l4.449-7.119,3.21.357a78.207,78.207,0,0,0,8.6.476.743.743,0,0,1,.234,1.449A10.712,10.712,0,0,1,27.1,29H25a1,1,0,0,0-.895,1.448l4.715,9.428a1.711,1.711,0,0,1,.18.762A.376.376,0,0,1,28.36,40.87ZM24,10h6v8H24ZM10,18.633V10H22v9a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V10H44V40H30.925a3.66,3.66,0,0,0-.316-1.018L30.118,38H42V36H29.118l-2.5-5H27.1a12.752,12.752,0,0,0,4.029-.653A2.744,2.744,0,0,0,30.257,25a76.435,76.435,0,0,1-8.377-.463l-3.331-.37-.006-.006Z"></path>
        <path d="M43.294,44.091l-5.057.722A2.62,2.62,0,0,0,36,47.381L29.87,46.155a7.035,7.035,0,0,0-4.491.538,2.494,2.494,0,0,0,.326,4.6l12.9,4.3a8.01,8.01,0,0,0,4.475.172l5.972-1.493a.99.99,0,0,0,.872.73l13,1L63.077,54,51,53.074V47H63V45H50a.989.989,0,0,0-.732.331A9.035,9.035,0,0,0,43.294,44.091Zm-.7,9.73a6.022,6.022,0,0,1-3.357-.129l-12.9-4.3a.494.494,0,0,1-.064-.91,5.023,5.023,0,0,1,3.205-.366l7.95,1.59A2.575,2.575,0,0,0,38.606,50H42V48H38.606a.607.607,0,0,1-.085-1.207l5.056-.722a7.076,7.076,0,0,1,4.89,1.109l.533.356V52.22Z"></path>
        <rect x="34" y="32" width="8" height="2"></rect>
      </g>
    </svg>
  );
}
