import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IconCloseEye from '../../component/SvgIcons/IconCloseEye';
import IconOpenEye from '../../component/SvgIcons/IconOpenEye';
import IconSignIn from '../../component/SvgIcons/IconSignIn';
import IconUser from '../../component/SvgIcons/IconUser';
import { AuthContext } from '../utils/AuthProvider';
import api from '../utils/axios';
import './style.css';
import { useToast } from '../utils/ToastProvider';

export default function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);  
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const notify = useToast();

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    let hasError = false;
    let errorMessage = '';    
   if (!username && !password) {
    errorMessage = 'Username and Password are required.';
    hasError = true;
  } else {
    // Check individual fields
    if (!username) {
      errorMessage = 'Username is required.';
      hasError = true;
    } else if (username.length < 2) {
      errorMessage = 'Username must be at least 2 characters.';
      hasError = true;
    } else if (!password) {
      errorMessage = 'Password is required.';
      hasError = true;
    } else if (password.length < 2) {
      errorMessage = 'Password must be at least 2 characters.';
      hasError = true;
    }
  }  
    if (hasError) {
      notify(errorMessage, 'error');
      setLoading(false);
      return; // Stop further processing if there are validation errors
    }
    try {
      const response = await api.post(
        '/GarsUserLogin',
        {
          UserID: username,
          Password: password,
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );    
            
      const userDetail = response.data.GarsUserDetail[0];
      
      if (userDetail && userDetail.LoginStatus === "1") {
        login(userDetail);
        navigate('/dashboard');
        notify('Login successful!', 'success');
      } else {
        notify('Invalid username or password', 'error');
      }
    } catch (error) {
      console.error('Login error:', error);
      notify('Failed to login. Please try again.', 'error');
    }finally {
      setLoading(false); // Stop loading after request completes
    }
  };

  return (
    <section className='loginpage'>
      <form className='loginform' onSubmit={handleLogin}>
        <h5>GARS Report</h5>      
        <div className='formfield'>
          <input
            type="text"
            placeholder="Enter User Name"            
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <span><IconUser /></span>             
        </div>
        <div className='formfield'>
          <input
             type={showPassword ? "text" : "password"}
            placeholder="Enter Password"            
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />                 
           <span onClick={() => setShowPassword(!showPassword)}>
            {showPassword ? <IconCloseEye /> : <IconOpenEye />}
          </span>
          
        </div>
        <button 
          type="submit" 
          className='btn btn-purpal' 
          disabled={loading} 
        >
          {loading ? (
            <div className="loader"></div> 
          ) : (
            <>Login <IconSignIn /></>
          )}
        </button>
      </form>
    </section>
  );
}
