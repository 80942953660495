import React from "react";

export default function IconSignIn() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M25.5858 16L21.0433 20.5425C20.8557 20.7301 20.75 20.9848 20.75 21.25C20.75 21.266 20.7504 21.282 20.7512 21.298C20.7631 21.5464 20.8671 21.7813 21.0429 21.9571C21.2304 22.1446 21.4848 22.25 21.75 22.25C22.0152 22.25 22.2696 22.1446 22.4571 21.9571L27.7071 16.7071C27.8946 16.5196 28 16.2652 28 16C28 15.7348 27.8946 15.4804 27.7071 15.2929L22.4572 10.043C22.2696 9.85536 22.0152 9.75 21.75 9.75C21.4848 9.75 21.2304 9.85536 21.0429 10.0429C20.8554 10.2304 20.75 10.4848 20.75 10.75C20.75 11.0152 20.8554 11.2696 21.0429 11.4571L25.5858 16Z"
        fill="#000"
      />
      <path
        d="M13 17H27C27.5523 17 28 16.5523 28 16C28 15.4477 27.5523 15 27 15H13C12.4477 15 12 15.4477 12 16C12 16.5523 12.4477 17 13 17Z"
        fill="#000"
      />
      <path
        d="M6 6H13C13.5523 6 14 5.55228 14 5C14 4.44772 13.5523 4 13 4H6C5.17157 4 4.58579 4.58579 4.58579 4.58579C4 5.17157 4 6 4 6V26C4 26.8284 4.58579 27.4142 4.58579 27.4142C5.17157 28 6 28 6 28H13C13.5523 28 14 27.5523 14 27C14 26.4477 13.5523 26 13 26H6V6Z"
        fill="#000"
      />
    </svg>
  );
}
