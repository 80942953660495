import React from "react";


export default function InvoiceDetails({activeItem }) {
  if (!activeItem) {
    return <div>No details available</div>;
  }  
  return (
    <div className="detailstable transfaldetailstable">
      <h5>Invoice Details</h5>
      <table className="table">     
        <tbody>
            <tr>
            <th>Invoice No</th> <td>{activeItem.InvoiceNo}</td>
            </tr>
            <tr>
            <th>Sales Man Name</th> <td>{activeItem.SalesManName}</td>
            </tr>

            <tr>
            <th>Customer Name</th> <td>{activeItem.CustomerName}</td>
            </tr>
            <tr>
            <th>Customer Mobile No.</th> <td>{activeItem.CustomerMobileNo}</td>
            </tr>
            
            
            <tr>
                <th>Invoice Date</th>
              <td>{activeItem.InvoiceDate}</td>
              </tr>
              <tr>
                <th>Invoice Time</th>
              <td>{activeItem.InvoiceTime}</td>
              </tr>
              <tr>
              <th>GoDown Name	</th>
              <td>{activeItem.GoDownName}</td>
              </tr>   
              <tr>
               <th>Price List</th>
              <td>{activeItem.PriceListName}</td>              
              </tr>
              <tr>
              <th>Total Amount</th>
              <td>{activeItem.TotalAmount}</td>   
              </tr> 
              <tr>
              <th>Vat Tax</th>
              <td>{activeItem.VatTax}</td>   
              </tr>
              <tr>
              <th>Taux</th>
              <td>{activeItem.Taux}</td>   
              </tr> 
              
              <tr>              
              <th>Net Amount</th>
              <td>{activeItem.NetAmountInFc}</td>   
              </tr>
              <tr>              
              <th>Net Amount In Dollar</th>
              <td>{activeItem.NetAmountInDollar}</td>   
              </tr>  
              
              {activeItem.DiscountAmount && (
            <tr>
              <th>Discount Amount</th>
              <td>{activeItem.DiscountAmount}</td>
            </tr>
          )}
              {activeItem.Narration && (
            <tr>
              <th>Narration</th>
              <td>{activeItem.Narration}</td>
            </tr>
          )}
              <tr>
              <th>IMEI Number</th>
              <td>{activeItem.IMEINumber}</td>
              </tr>                                  
        </tbody>
      </table>
    </div>
  );
}
