import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import IconSignIn from "../SvgIcons/IconSignIn";
import IconUser from "../SvgIcons/IconUser";
import IconName from "../SvgIcons/IconName";
import "./style.css";
import { AuthContext } from "../../pages/utils/AuthProvider";

export default function Header({ pageTitle }) {
  const [menuVisible, setMenuVisible] = useState(false);
  const { isAuthenticated, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const username = localStorage.getItem("UserName");
  const userID = localStorage.getItem("UserId");

  const handleLogout = async () => {
    try {
      // Call logout function from AuthContext
      logout();

      // Redirect to login page
      navigate("/");
      // Prevent user from going back to the previous authenticated pages
      window.history.pushState(null, "", window.location.href);
      window.onpopstate = () => {
        window.history.pushState(null, "", window.location.href);
      };
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleClickOutside = useCallback((event) => {
    if (menuVisible && !event.target.closest(".user")) {
      setMenuVisible(false);
    }
  }, [menuVisible]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, [handleClickOutside]);

  if (!isAuthenticated) {
    return null; // Or redirect to login if needed
  }

  return (
    <header className="header-top d-flex align-items-center justify-content-between">
      <div className="page-title d-flex align-items-center">
        <div className="logoinner">
          <Link to="/dashboard">
            <img src="../images/logo.png" alt="logo" />
          </Link>
        </div>
      </div>
      <h2>{pageTitle}</h2>
      <div className="user">
        <Link onClick={toggleMenu} className="usericon">
          <IconUser />
        </Link>
        {menuVisible && (
          <ul className="user-menu">
            <li><IconName /><strong>{username}</strong></li>
            <li><IconName />{userID}</li>
            <li>
              <button className="logout" onClick={handleLogout}>
                <IconSignIn /> LogOut
              </button>
            </li>
          </ul>
        )}
      </div>
    </header>
  );
}
