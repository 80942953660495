import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from '../../pages/Dashboard';
import Login from '../../pages/Login';
import TotalInvoice from '../../pages/TotalInvoice';
import TotalReceived from '../../pages/TotalReceived';
import TotalStockQuantity from '../../pages/TotalStockQuantity';
import TotalTransfer from '../../pages/TotalTransfer';
import { AuthProvider } from '../../pages/utils/AuthProvider';


export default function NavigationRouting() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/total-invoice" element={<TotalInvoice />} />
        <Route path="/total-transfer" element={<TotalTransfer />} />
        <Route path="/total-received" element={<TotalReceived />} />
        <Route path="/total-stock-quantity" element={<TotalStockQuantity />} />
      </Routes>


      {/* <AuthProvider>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} />} />
        <Route path="/total-invoice" element={<ProtectedRoute element={<TotalInvoice />} />} />
        <Route path="/total-transfer" element={<ProtectedRoute element={<TotalTransfer />} />} />
        <Route path="/total-received" element={<ProtectedRoute element={<TotalReceived />} />} />
        <Route path="/total-stock-quantity" element={<ProtectedRoute element={<TotalStockQuantity />} />} />
      </Routes>
    </AuthProvider> */}
    </AuthProvider>
  );
}
