import React from "react";

export default function IconView() {
  return (
    <svg
      id="fi_11502607"
      enableBackground="new 0 0 512 512"
      height="18"
      viewBox="0 0 512 512"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="m460.465 264.803c-41.315 74.193-119.667 120.282-204.438 120.282-84.826 0-163.177-46.089-204.493-120.282-3.182-5.761-3.182-11.851 0-17.607 41.316-74.192 119.667-120.276 204.493-120.276 84.771 0 163.122 46.084 204.438 120.276 3.238 5.756 3.238 11.846 0 17.607zm26.392-32.317c-46.638-83.767-135.085-135.798-230.83-135.798-95.8 0-184.247 52.031-230.885 135.798-8.34 14.946-8.34 32.081 0 47.016 46.638 83.767 135.085 135.809 230.885 135.809 95.745 0 184.192-52.042 230.83-135.809 8.34-14.934 8.34-32.07 0-47.016zm-230.83 85.528c34.183 0 62.001-27.818 62.001-62.017s-27.818-62.017-62.001-62.017c-34.238 0-62.056 27.818-62.056 62.017s27.819 62.017 62.056 62.017zm0-154.266c-50.918 0-92.288 41.387-92.288 92.25 0 50.874 41.371 92.244 92.288 92.244 50.863 0 92.233-41.371 92.233-92.244 0-50.863-41.37-92.25-92.233-92.25z"
        fillRule="evenodd"
      ></path>
    </svg>
  );
}
