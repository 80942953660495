import React from "react";

export default function IconName() {
  return (
    <svg
      id="fi_3381635"
      enableBackground="new 0 0 512 512"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="m464.866 72.3h-417.732c-26.337 0-47.134 21.39-47.134 47.134v273.133c0 26.336 21.39 47.133 47.134 47.133h417.732c26.337 0 47.134-21.39 47.134-47.134v-273.132c0-26.338-21.39-47.134-47.134-47.134zm17.134 320.266c0 9.456-7.658 17.134-17.134 17.134h-417.732c-9.456 0-17.134-7.658-17.134-17.134v-273.132c0-9.456 7.658-17.134 17.134-17.134h417.732c9.456 0 17.134 7.658 17.134 17.134z"></path>
        <path d="m200.74 292.703c8.716-9.759 14.027-22.62 14.027-36.703 0-30.419-24.748-55.167-55.167-55.167s-55.166 24.748-55.166 55.167c0 14.083 5.311 26.944 14.026 36.702-22.843 13.926-38.127 39.077-38.127 67.731 0 8.284 6.716 15 15 15h128.533c8.284 0 15-6.716 15-15 0-28.654-15.284-53.804-38.126-67.73zm-41.14-61.87c13.877 0 25.167 11.29 25.167 25.167s-11.29 25.167-25.167 25.167-25.166-11.29-25.166-25.167 11.289-25.167 25.166-25.167zm-46.933 114.601c6.36-19.854 24.995-34.267 46.933-34.267s40.572 14.412 46.933 34.267z"></path>
        <path d="m416.667 216.9h-112.467c-8.284 0-15 6.716-15 15s6.716 15 15 15h112.467c8.284 0 15-6.716 15-15s-6.716-15-15-15z"></path>
        <path d="m416.667 136.566h-321.334c-8.284 0-15 6.716-15 15s6.716 15 15 15h321.334c8.284 0 15-6.716 15-15s-6.716-15-15-15z"></path>
        <path d="m416.667 281.167h-112.467c-8.284 0-15 6.716-15 15s6.716 15 15 15h112.467c8.284 0 15-6.716 15-15s-6.716-15-15-15z"></path>
        <path d="m384.533 345.434h-80.333c-8.284 0-15 6.716-15 15s6.716 15 15 15h80.333c8.284 0 15-6.716 15-15s-6.716-15-15-15z"></path>
      </g>
    </svg>
  );
}
