import { BrowserRouter } from 'react-router-dom';
import NavigationRouting from './component/NavigationRouting';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/assest/css/fonts.css';
import '../src/assest/css/style.css'
import { ToastProvider } from './pages/utils/ToastProvider';

function App() {
  return (
    <ToastProvider>
    <BrowserRouter>      
    <NavigationRouting/>
    </BrowserRouter>
    </ToastProvider>
  );
}

export default App;
